<template>
    <filter-list v-model="list" @init='init' ref="list" url="/serviceProvider/all" :filters="filters" labelWidth="78px">
        <template v-for="item in list">
            <!-- <patent-row :key="item.id" :info="item"></patent-row> -->
            <service-provider-row :key="item.id" :info="item"></service-provider-row>
        </template>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import ServiceProviderRow from '../../components/list/ServiceProviderRow.vue';
export default {
    components: { FilterList, ServiceProviderRow },
    data() {
        return {
            fieldOptions: [], //领域,
            list: []
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            return [
                {
                    name: '擅长领域',
                    key: 'field',
                    list: [...this.fieldOptions]
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 6 })
                .then(res => {
                    if (res.length > 0) {
                        this.fieldOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }

                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped></style>
